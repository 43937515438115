import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Button } from 'reactstrap';

import trackEvent from '../components/analytics';
import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
  ViewChoice,
  ViewImgTop,
} from '../components/layout';
import SEO from '../components/seo';

import Borne1 from '../images/Bornes-1.png';
import Borne2 from '../images/Bornes-2.png';
import Borne3 from '../images/Bornes-3.png';

import Background from '../images/landscape-1.jpg';
import Background2 from '../images/quizz-correction.jpg';

import data1 from '../quizz/quizz-final-1.json';
import data2 from '../quizz/quizz-final-2.json';
import data3 from '../quizz/quizz-final-3.json';

import Question1 from '../audio/fr/voixoff_FR_41.mp3';
import Question1Correct from '../audio/fr/voixoff_FR_42.mp3';

import Question2 from '../audio/fr/voixoff_FR_43.mp3';
import Question2Correct from '../audio/fr/voixoff_FR_44.mp3';

import Question3 from '../audio/fr/voixoff_FR_45.mp3';
import Question3Correct from '../audio/fr/voixoff_FR_46.mp3';

import Question4 from '../audio/fr/voixoff_FR_47.mp3';
import Question4Correct from '../audio/fr/voixoff_FR_48.mp3';

import Question5 from '../audio/fr/voixoff_FR_49.mp3';
import Question5Correct from '../audio/fr/voixoff_FR_50.mp3';

import Question6 from '../audio/fr/voixoff_FR_51.mp3';
import Question6Correct from '../audio/fr/voixoff_FR_52.mp3';

import Question7 from '../audio/fr/voixoff_FR_53.mp3';
import Question7Correct from '../audio/fr/voixoff_FR_54.mp3';

import Question8 from '../audio/fr/voixoff_FR_55.mp3';
import Question8Correct from '../audio/fr/voixoff_FR_56.mp3';

import Question9 from '../audio/fr/voixoff_FR_57.mp3';
import Question9Correct from '../audio/fr/voixoff_FR_58.mp3';

const ViewArticle = styled.article`
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  justify-items: center;
  align-items: center;

  max-width: 100%;
  background-image: ${(props) =>
    props.correction ? `url(${Background2})` : `url(${Background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  overflow-y: auto;
  padding-top: 2rem;
`;

const ViewForm = styled.form`
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  padding: 1rem 4rem 5rem 6rem;
  align-content: flex-start;
  line-height: 1.2;
  font-size: 1.1rem;
  animation: bounceIn;
  animation-duration: 1s;
`;

const ViewLabel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0 0.1rem 0;
  text-align: left;
  font-weight: bold;

  p {
    font-size: 1.2rem;
    font-family: 'Roboto Slab' !important;
    line-height: 1.2;
    padding-right: 1rem;
  }
`;

const ViewAnswer = styled.label`
  display: flex;
  align-items: center;
  border: 3px solid #ddd;
  background: #fff;
  border-radius: 10px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  ${(props) => (props.correction ? '' : 'cursor: pointer;')}
  margin: 0.1rem 0 0.1rem 0;
  text-align: left;

  ${(props) => props.selected && 'border-color: #17a2b8;'}

  ${(props) => props.correct && 'border-color: #28a745;'}

  ${(props) => props.incorrect && 'border-color: #dc3545;'}

  &:hover {
    ${(props) => (props.correction ? '' : 'border: 3px solid #bfd6e7;')}
  }
`;

const ViewChoiceForm = styled.div`
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  background: #28a745;
  border-radius: 100px;
  text-align: center;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  color: #fff;
  line-height: 1.4;
`;

const ViewChoice2 = styled(ViewChoice)`
  margin-left: 1rem;
  margin-top: 2rem;
  max-width: 400px;
  transform: rotate(0deg);
`;

const ViewPlayerQuestions = styled.div`
  margin-bottom: 1rem;
`;

const ViewButton = styled(Button)`
  margin-top: 1rem;
`;

class Part4_QuizzForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correction: false,

      answer_content_1: 'N/A',
      answer_correct_1: false,
      answer_selected_1: 0,

      answer_content_2: 'N/A',
      answer_correct_2: false,
      answer_selected_2: 0,

      answer_content_3: 'N/A',
      answer_correct_3: false,
      answer_selected_3: 0,

      answer_content_4: 'N/A',
      answer_correct_4: false,
      answer_selected_4: 0,

      answer_content_5: 'N/A',
      answer_correct_5: false,
      answer_selected_5: 0,

      answer_content_6: 'N/A',
      answer_correct_6: false,
      answer_selected_6: 0,

      answer_content_7: 'N/A',
      answer_correct_7: false,
      answer_selected_7: 0,

      answer_content_8: 'N/A',
      answer_correct_8: false,
      answer_selected_8: 0,

      answer_content_9: 'N/A',
      answer_correct_9: false,
      answer_selected_9: 0,

      timestamp: Date.now(),
    };
    this.onChangeAnswer1 = this.onChangeAnswer1.bind(this);
    this.onChangeAnswer2 = this.onChangeAnswer2.bind(this);
    this.onChangeAnswer3 = this.onChangeAnswer3.bind(this);
    this.onChangeAnswer4 = this.onChangeAnswer4.bind(this);
    this.onChangeAnswer5 = this.onChangeAnswer5.bind(this);
    this.onChangeAnswer6 = this.onChangeAnswer6.bind(this);
    this.onChangeAnswer7 = this.onChangeAnswer7.bind(this);
    this.onChangeAnswer8 = this.onChangeAnswer8.bind(this);
    this.onChangeAnswer9 = this.onChangeAnswer9.bind(this);
    this.addQuizz = this.addQuizz.bind(this);
  }

  onChangeAnswer1 = (e) => {
    this.setState({
      answer_correct_1: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_1: e.target.value,
      answer_selected_1: e.target.dataset.selected,
    });
  };

  onChangeAnswer2 = (e) => {
    this.setState({
      answer_correct_2: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_2: e.target.value,
      answer_selected_2: e.target.dataset.selected,
    });
  };

  onChangeAnswer3 = (e) => {
    this.setState({
      answer_correct_3: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_3: e.target.value,
      answer_selected_3: e.target.dataset.selected,
    });
  };

  onChangeAnswer4 = (e) => {
    this.setState({
      answer_correct_4: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_4: e.target.value,
      answer_selected_4: e.target.dataset.selected,
    });
  };

  onChangeAnswer5 = (e) => {
    this.setState({
      answer_correct_5: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_5: e.target.value,
      answer_selected_5: e.target.dataset.selected,
    });
  };

  onChangeAnswer6 = (e) => {
    this.setState({
      answer_correct_6: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_6: e.target.value,
      answer_selected_6: e.target.dataset.selected,
    });
  };

  onChangeAnswer7 = (e) => {
    this.setState({
      answer_correct_7: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_7: e.target.value,
      answer_selected_7: e.target.dataset.selected,
    });
  };

  onChangeAnswer8 = (e) => {
    this.setState({
      answer_correct_8: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_8: e.target.value,
      answer_selected_8: e.target.dataset.selected,
    });
  };

  onChangeAnswer9 = (e) => {
    this.setState({
      answer_correct_9: e.target.dataset.correct === 'yes' ? true : false,
      answer_content_9: e.target.value,
      answer_selected_9: e.target.dataset.selected,
    });
  };

  addQuizz = () => {
    var myQuizzToTop = document.getElementById('content');
    myQuizzToTop.scrollTop = 0;

    var myHeaders = new Headers();
    myHeaders.append('x-api-key', process.env.GATSBY_APPSYNC_KEY);
    myHeaders.append('Content-Type', 'application/json');

    var graphqlQuery1 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_1',
          answer_content: this.state.answer_content_1,
          answer_correct: this.state.answer_correct_1,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery2 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_2',
          answer_content: this.state.answer_content_2,
          answer_correct: this.state.answer_correct_2,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery3 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_3',
          answer_content: this.state.answer_content_3,
          answer_correct: this.state.answer_correct_3,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery4 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_4',
          answer_content: this.state.answer_content_4,
          answer_correct: this.state.answer_correct_4,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery5 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_5',
          answer_content: this.state.answer_content_5,
          answer_correct: this.state.answer_correct_5,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery6 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_6',
          answer_content: this.state.answer_content_6,
          answer_correct: this.state.answer_correct_6,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery7 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_7',
          answer_content: this.state.answer_content_7,
          answer_correct: this.state.answer_correct_7,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery8 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_8',
          answer_content: this.state.answer_content_8,
          answer_correct: this.state.answer_correct_8,
          timestamp: this.state.timestamp,
        },
      },
    });

    var graphqlQuery9 = JSON.stringify({
      query: `mutation createQuizAnswer ($inputData: CreateQuizAnswerInput!) {
        createQuizAnswer (input: $inputData) {
        id
        quiz_id
        question_id
        answer_content
        answer_correct
        timestamp
        }
       }`,
      variables: {
        inputData: {
          quiz_id: 'Quizz_4',
          question_id: 'Question_9',
          answer_content: this.state.answer_content_9,
          answer_correct: this.state.answer_correct_9,
          timestamp: this.state.timestamp,
        },
      },
    });

    var requestOptions1 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery1,
      redirect: 'follow',
    };

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery2,
      redirect: 'follow',
    };

    var requestOptions3 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery3,
      redirect: 'follow',
    };

    var requestOptions4 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery4,
      redirect: 'follow',
    };

    var requestOptions5 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery5,
      redirect: 'follow',
    };

    var requestOptions6 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery6,
      redirect: 'follow',
    };

    var requestOptions7 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery7,
      redirect: 'follow',
    };

    var requestOptions8 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery8,
      redirect: 'follow',
    };

    var requestOptions9 = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery9,
      redirect: 'follow',
    };

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions1).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions2).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions3).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions4).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions5).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions6).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions7).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions8).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions9).then((response) =>
      response.text(),
    );
    // .then((result) => console.log(result))
    // .catch((error) => console.log('error', error));

    this.setState({
      correction: true,
    });
  };

  render() {
    return (
      <Layout location={typeof window !== 'undefined' ? location : null}>
        <SEO pathname="/fr/part-4-quizz-form" />

        <ViewArticle id="content" correction={this.state.correction == true && true}>
          <ViewForm>
            <ViewChoice2>
              <ViewImgTop src={Borne1} />
              Gérer les revenus de mon activité
            </ViewChoice2>
            {data1.questions.map((item, indexQuestion) => {
              return (
                <Fragment key={indexQuestion}>
                  <ViewLabel>
                    <p>{item.question}</p>
                    <ViewPlayerQuestions>
                      {indexQuestion === 0 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question1Correct
                              : Question1
                          }
                        />
                      )}
                      {indexQuestion === 1 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question2Correct
                              : Question2
                          }
                        />
                      )}
                      {indexQuestion === 2 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question3Correct
                              : Question3
                          }
                        />
                      )}
                    </ViewPlayerQuestions>
                    {item.answers.map((answer, index) => {
                      return (
                        <Fragment key={index}>
                          {this.state.correction != true ? (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_1 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer1}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_2 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer2}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_3 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer3}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_1 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_2 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_3 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      );
                    })}
                  </ViewLabel>
                </Fragment>
              );
            })}
            <ViewChoice2>
              <ViewImgTop src={Borne2} />
              Gérer mon argent en bon père de famille
            </ViewChoice2>
            {data2.questions.map((item, indexQuestion) => {
              return (
                <Fragment key={indexQuestion}>
                  <ViewLabel>
                    <p>{item.question}</p>
                    <ViewPlayerQuestions>
                      {indexQuestion === 0 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question4Correct
                              : Question4
                          }
                        />
                      )}
                      {indexQuestion === 1 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question5Correct
                              : Question5
                          }
                        />
                      )}
                      {indexQuestion === 2 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question6Correct
                              : Question6
                          }
                        />
                      )}
                    </ViewPlayerQuestions>
                    {item.answers.map((answer, index) => {
                      return (
                        <Fragment key={index}>
                          {this.state.correction != true ? (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_4 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer4}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_5 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer5}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_6 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer6}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_4 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_5 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_6 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      );
                    })}
                  </ViewLabel>
                </Fragment>
              );
            })}
            <ViewChoice2>
              <ViewImgTop src={Borne3} />
              Mieux organiser mon activité
            </ViewChoice2>
            {data3.questions.map((item, indexQuestion) => {
              return (
                <Fragment key={indexQuestion}>
                  <ViewLabel>
                    <p>{item.question}</p>
                    <ViewPlayerQuestions>
                      {indexQuestion === 0 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question7Correct
                              : Question7
                          }
                        />
                      )}
                      {indexQuestion === 1 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question8Correct
                              : Question8
                          }
                        />
                      )}
                      {indexQuestion === 2 && (
                        <Player
                          isQuizz
                          sound={
                            this.state.correction == true
                              ? Question9Correct
                              : Question9
                          }
                        />
                      )}
                    </ViewPlayerQuestions>
                    {item.answers.map((answer, index) => {
                      return (
                        <Fragment key={index}>
                          {this.state.correction != true ? (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_7 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer7}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_8 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer8}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  htmlFor={answer.id}
                                  selected={
                                    this.state.answer_selected_9 == index + 1 && true
                                  }
                                >
                                  <input
                                    onChange={this.onChangeAnswer9}
                                    name={item.id}
                                    id={answer.id}
                                    value={answer.answer}
                                    type="radio"
                                    data-correct={answer.correct}
                                    data-selected={index + 1}
                                  />
                                  <ViewChoiceForm>{answer.choice}</ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {indexQuestion === 0 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_7 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 1 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_8 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                              {indexQuestion === 2 && (
                                <ViewAnswer
                                  correct={answer.correct === 'yes' && true}
                                  incorrect={
                                    answer.correct != 'yes' &&
                                    this.state.answer_selected_9 == index + 1 &&
                                    true
                                  }
                                  correction
                                >
                                  <input type="radio" disabled />
                                  <ViewChoiceForm>
                                    {answer.choice}
                                  </ViewChoiceForm>{' '}
                                  {answer.answer}
                                </ViewAnswer>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      );
                    })}
                  </ViewLabel>
                </Fragment>
              );
            })}
            {this.state.correction != true && (
              <ViewButton
                color="success"
                onClick={() => {
                  this.addQuizz();
                  trackEvent(
                    'Quizz Page Part 4',
                    'User saw quizz correction',
                    'yes',
                  );
                }}
              >
                <i className="fa fa-check" /> Valider
              </ViewButton>
            )}
          </ViewForm>
          <Link to="/fr/part-4">
            <ViewButtonPrevious color="warning">
              <i className="fa fa-chevron-left fa" />
            </ViewButtonPrevious>
          </Link>
          {this.state.correction == true && (
            <Link to="/fr/part-4-success">
              <ViewButtonNext
                color="warning"
                onClick={() => {
                  localStorage.setItem('Step4Complete', 'yes');
                }}
              >
                <i className="fa fa-chevron-right" />
              </ViewButtonNext>
            </Link>
          )}
        </ViewArticle>

        <ShareButtons />
      </Layout>
    );
  }
}

export default Part4_QuizzForm;
